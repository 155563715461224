import { Toggle } from '../../helpers/toggle';
import './accordion.scss';

export class Accordion {

    constructor(element: Element) {
        element.querySelectorAll('[data-accordion-item]').forEach((n) => {
            const targetSelector = n.getAttribute('data-accordion-item') as string;
            const targets = element.querySelectorAll(targetSelector);
            new Toggle(n, targets, 'is-active');
        });
    }
}
