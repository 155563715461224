export class Toggle {

    public isActive = false;

    constructor(private el: Element, private targets: NodeListOf<Element>, private className: string, bindClick = true) {
        this.isActive = el.classList.contains(className);
        if (bindClick) {
            el.addEventListener('click', (_event) => this.toggle());
        }
    }

    public toggle(){
        this.isActive = !this.isActive;
        return this.isActive ? this.enable() : this.disable();
    }

    public enable() {
        this.isActive = true;
        this.el.classList.add(this.className);
        this.targets.forEach(n => n.classList.add(this.className));
        return this.isActive;
    }

    public disable() {
        this.isActive = false;
        this.el.classList.remove(this.className);
        this.targets.forEach(n => n.classList.remove(this.className));
        return this.isActive;
    }
}
