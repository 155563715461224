// main entry for scss
import './styles.scss';



import { Accordion } from './components/accordion/accordion';
import { Banner } from './components/banner/banner';
import { Demo } from './components/demo/demo.component';
import { Grid01 } from './components/grid01/grid01';
import { Grid02 } from './components/grid02/grid02';
import { Navigation } from './components/navigation/navigation';
import { ResponsiveImages } from './components/responsive-images/responsive-images';
import { Slider01 } from './components/slider01/slider01';
import { Slider02 } from './components/slider02/slider02';
import { TextImage } from './components/text-image/text-image';
import { TextStandard } from './components/text-standard/text-standard';
import { VimeoEmbed } from './components/video/vimeo/vimeo';
import { YouTubeEmbed } from './components/video/youtube/youtube';
import { Cookiebar } from './components/cookiebar/cookiebar';
import { ResponsiveTable } from './components/responsive-tables/responsive-table';
import { BodyLock } from './components/body-lock/body-lock';
import { OpacityLayer } from './components/opacity-layer/opacity-layer';
import { BehaviorSubject } from 'rxjs';
import { ImageGrid } from './components/image-grid/image-grid';
import { Gallery } from './components/gallery/gallery';

// bootstrap main App when DOMContentLOADED
document.addEventListener('DOMContentLoaded', () => {
    const t0 = performance.now();

    const t1 = performance.now();

    const msBrowser = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(window.navigator.userAgent);
    const msVersion: number | null = (() => {
        return msBrowser ? parseInt(msBrowser[2], 10) : null;
    })();

    // Add ie-(version) class for css workarounds
    if (msVersion !== null) {
        document.body.classList.add('ie-' + msVersion);
    }

    if (msVersion !== null && msVersion <= 10) {
        alert(`Din browser, Internet Explorer ${msVersion}, er ikke understøttet!\n
            Vi anbefaler at skifter til nyeste version af Goolge Chrome, Mozilla Firefox, Safari eller Microsoft Edge.`);
    }

    if (msBrowser !== null && msBrowser.includes('Edge/17')) {
        alert(`Din version af Microsoft Edge er ikke opdateret og ikke understøttet!\n
            Vi anbefaler at skifter til nyeste version af Goolge Chrome, Mozilla Firefox, Safari eller Microsoft Edge.`);
    }

    const bodyLock = new BodyLock();

    const opacityLayer = new OpacityLayer();
        const layerState = new BehaviorSubject<string>('');

    // ---- Component Usage ----
    new Banner();
    new Demo();
    new Grid01();
    new Grid02();
    new Navigation(opacityLayer, layerState);
    new ResponsiveImages();
    new TextImage();
    new TextStandard();
    new Cookiebar();
    new ImageGrid();

    document.querySelectorAll('.slider01').forEach((n) => new Slider01(n));
    document.querySelectorAll('.slider02').forEach((n) => new Slider02(n));
    document.querySelectorAll('[data-video-vimeo]').forEach((n) => new VimeoEmbed(n));
    document.querySelectorAll('[data-video-youtube]').forEach((n) => new YouTubeEmbed(n));
    document.querySelectorAll('[data-accordion]').forEach((n) => new Accordion(n));
    document.querySelectorAll('[data-gallery]').forEach((n) => new Gallery(n as HTMLElement));
    document.querySelectorAll('.rte table').forEach((table) => new ResponsiveTable(table));

    const t2 = performance.now();

    if (window.location.host.includes('localhost') || window.location.hostname.includes('illumi.dk')) {
        console.log('Rendering Templates took: ' + Math.round(t1 - t0) + ' milliseconds.');
        console.log('Bootstrapping Components took: ' + Math.round(t2 - t1) + ' milliseconds.');
    }

    // const body = document.getElementsByTagName('BODY')[0];
    // angular.bootstrap(body, [ngModule.name]);
});

// // ServiceWorker is a progressive technology. Ignore unsupported browsers
// if ('serviceWorker' in navigator) {
//     console.log('CLIENT: service worker registration in progress.');
//     navigator.serviceWorker.register('service-worker.js').then(
//         function() {
//             console.log('CLIENT: service worker registration complete.');
//         },
//         function() {
//             console.log('CLIENT: service worker registration failure.');
//         }
//     );
// } else {
//     console.log('CLIENT: service worker is not supported.');
// }
