export class ResponsiveTable {
    constructor(table: Element | undefined, tableClasses: string[] = []) {
        if (!!table && !!table.parentNode) {
            // Create div wrapper
            const div = document.createElement('div');
            // Add default table class
            table.classList.add('table');
            // Add any aditional classes, etc. table-striped
            tableClasses.forEach((c) => table.classList.add(c));
            // Add table-responsive to wrapper
            div.classList.add('table-responsive');
            // Replace table element with wrapper
            table.parentNode.replaceChild(div, table);
            // Append table to wrapper
            div.appendChild(table);
        }
    }
}
