import './../player.scss';

/**
 * API Reference: https://developers.google.com/youtube/iframe_api_reference
 * Types require: npm install @types/youtube
 * Add "youtube" to types in tsconfig.json
 */
export class YouTubeEmbed {

    constructor(private component: Element) {
        this.playerContainer = this.component.getElementsByClassName('player-container')[0] as HTMLElement;
        this.playButton = this.component.getElementsByClassName('play-button')[0] as HTMLElement;
        this.youtubeId = this.component.getAttribute('data-video-youtube') as string;

        if (!YouTubeEmbed.apiReady) {
            YouTubeEmbed.appendScript();
        }

        YouTubeEmbed.apiReady.then(() => {
            this.createPlayer(this.playerContainer, this.youtubeId);
        });

        this.playButton.addEventListener('click', () => this.play());
    }
    private static apiReady: Promise<void>;

    private youtubeId: string;
    private player!: YT.Player;
    private playerReady!: Promise<void>;

    private playButton: HTMLElement;
    private playerContainer: HTMLElement;

    // This loads the youtube script asyncrosly
    private static appendScript() {
        YouTubeEmbed.apiReady = new Promise<void>((resolve) => {
            // tslint:disable-next-line: only-arrow-functions
            (window as any).onYouTubeIframeAPIReady = () => resolve();
        });

        const scriptEl = document.createElement('script');
        scriptEl.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(scriptEl);
    }

    public play() {
        this.playerReady.then((n) => this.player.playVideo());
    }

    private createPlayer(element: HTMLElement, youtubeId: string) {
        // todo set heights
        console.log(element, youtubeId);
        this.playerReady = new Promise<void>((resolve, reject) => {
            this.player = new YT.Player(element as any, {
                videoId: youtubeId,
                playerVars: {
                    modestbranding: 1,
                    playsinline: 1,
                    rel: 0
                    // autoplay: <YT.AutoPlay>1
                }, // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5#Parameters
                events: {
                    onReady: () => {
                        this.component.classList.add('is-ready');
                        resolve();
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    onPlaybackQualityChange: () => {},
                    onStateChange: (event: YT.OnStateChangeEvent) => {

                        // Cleanup classes before adding a new state class
                        this.component.classList.remove('is-cued');
                        this.component.classList.remove('is-buffering');
                        this.component.classList.remove('is-playing');
                        this.component.classList.remove('is-paused');
                        this.component.classList.remove('is-ended');

                        switch (event.data) {
                            case YT.PlayerState.CUED:
                                this.component.classList.add('is-cued');
                                break;
                            case YT.PlayerState.BUFFERING:
                                this.component.classList.add('is-buffering');
                                break;
                            case YT.PlayerState.PLAYING:
                                this.component.classList.add('is-playing');
                                break;
                            case YT.PlayerState.PAUSED:
                                this.component.classList.add('is-paused');
                                break;
                            case YT.PlayerState.ENDED:
                                this.component.classList.add('is-ended');
                                break;
                            default:
                                console.log('Unknown YouTube Event:' + event.data);
                                break;
                        }
                    }
                }
            });
        });
    }
}
