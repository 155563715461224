import './gallery.scss';
import galleryTemplate from './gallery.template.html';

import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

interface IGalleryItem extends PhotoSwipe.Item {
    title: string;
    breakpoints: {
        LG: string;
        MD: string;
        SM: string;
        XS: string;
    };
}
export class Gallery {
    private static hasTemplate = false;

    private photoswipeOptions: PhotoSwipeUI_Default.Options = {
        shareEl: false,
        counterEl: false
    };

    private viewportSize = 'XS';
    private firstResize = true;

    private static parseHTML(str: string): HTMLCollection {
        const temp = document.implementation.createHTMLDocument('');
        temp.body.innerHTML = str;
        return temp.body.children;
    }

    private static addTemplate() {
        const htmlCollection = Gallery.parseHTML(galleryTemplate);
        Array.from(htmlCollection).forEach((el) => document.body.appendChild(el));
        Gallery.hasTemplate = true;
    }

    private static getSizesFromUrl(url: string) {
        const chunks = url.split('&');
        const width = chunks.find((n) => n.includes('width='))?.replace('width=', '') as string;
        const height = chunks.find((n) => n.includes('height='))?.replace('height=', '') as string;
        return { w: parseInt(width, 10), h: parseInt(height, 10) };
    }

    constructor(element: HTMLElement) {
        if (!Gallery.hasTemplate) {
            Gallery.addTemplate();
        }
        const galleryKey = element.getAttribute('data-gallery') as string;
        const index = element.getAttribute('data-gallery-index') as string;
        const pswpElement = document.querySelectorAll('.pswp')[0] as HTMLElement;

        if (!!galleryKey && index !== undefined) {
            element.addEventListener('click', () => {
                const items = (window as any)[galleryKey] as IGalleryItem[];
                this.initGallery(pswpElement, items, parseInt(index, 10));
            });
        }
    }

    private initGallery(pswpElement: HTMLElement, items: IGalleryItem[], index: number) {
        const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, Object.assign({}, this.photoswipeOptions, { index }));

        gallery.listen('beforeResize', () => {

            const realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio;

            let viewportSize = 'XS';

            if (realViewportWidth > 1201) {
                viewportSize = 'LG';
            } else if (realViewportWidth > 1001) {
                viewportSize = 'MD';
            } else if (realViewportWidth > 801) {
                viewportSize = 'SM';
            } else if (realViewportWidth > 320) {
                viewportSize = 'XS';
            }

            if (viewportSize !== this.viewportSize && !this.firstResize) {
                this.viewportSize = viewportSize;
                this.firstResize = false;
                gallery.invalidateCurrItems();
            } else {
                this.viewportSize = viewportSize;
            }
        });

        gallery.listen('gettingData', (_index, item: IGalleryItem) => {
            let url = '';
            switch (this.viewportSize) {
                case 'LG':
                    url = item.breakpoints.LG;
                    break;
                case 'MD':
                    url = item.breakpoints.MD;
                    break;
                case 'SM':
                    url = item.breakpoints.SM;
                    break;
                case 'XS':
                    url = item.breakpoints.XS;
                    break;
                default:
                    url = item.breakpoints.XS;
                    break;
            }
            Object.assign(item, Gallery.getSizesFromUrl(url), { src: url });
        });

        gallery.init();
    }
}
