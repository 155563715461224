import './cookiebar.scss';
import { Cookie } from '../../helpers/cookie';

export class Cookiebar {
    private cookiebar: HTMLElement;
    private acceptBtn: HTMLElement;

    private cookieName = 'allowCookies';

    constructor(private onAccept?: () => void) {
        this.cookiebar = document.getElementById('cookiebar') as HTMLElement;
        this.acceptBtn = document.getElementById('cookies-accept') as HTMLElement;


        if (!this.cookiebar || !this.acceptBtn) {
            return;
        }

        const hasCookie = Cookie.get(this.cookieName);

        if (hasCookie !== null && this.onAccept !== undefined) {
            this.onAccept();
        } else {
            this.cookiebar.classList.add('is-initialised');
            setTimeout(() => {
                this.acceptBtn.addEventListener('click', () => this.hideCookiebar());
                this.displayCookiebar();
            }, 1000);
        }
    }

    private displayCookiebar() {
        this.cookiebar.classList.add('is-active');
    }

    private hideCookiebar() {
        this.cookiebar.classList.remove('is-active');
        Cookie.set(this.cookieName, 'true', 30, true);
    }
}
