import './navigation.scss';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OpacityLayer } from '../opacity-layer/opacity-layer';

export class Navigation {

    private navToggle = document.getElementById('nav-toggle') as HTMLElement;
    private navToggleTarget = document.getElementById('nav-toggle-target') as HTMLElement;

    constructor(private opacityLayer: OpacityLayer, private layerState: BehaviorSubject<string>) {

        this.layerState.pipe(filter((n) => n !== 'navigation')).subscribe(() => {
            this.navToggle.classList.remove('is-active');
            this.navToggleTarget.classList.remove('is-active');
        });

        if (!!this.navToggle) {
            this.navToggle.addEventListener('click', (event) => {
                console.log('toggle');

                if (this.navToggle.classList.contains('is-active')) {
                    this.close();
                } else {
                    this.open();
                }
            });
        }
    }

    public close() {
        this.navToggle.classList.remove('is-active');
        this.navToggleTarget.classList.remove('is-active');
        this.opacityLayer.disable();
    }

    public open() {
        this.layerState.next('navigation');
        this.navToggle.classList.add('is-active');
        this.navToggleTarget.classList.add('is-active');
        this.opacityLayer.enable();
    }
}
