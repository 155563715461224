import * as Hammer from 'hammerjs';
import { debounce } from '../../helpers/debounce';
import './slider02.scss';

export class Slider02 {
    private viewEl: HTMLElement;
    private viewWidthEl: Element;
    private panelEl: HTMLElement;
    private prevButton: Element;
    private nextButton: Element;
    // private indicatorContainerEL: Element;
    private slideItems: Element[];

    private currentItem = 0;
    private slideCount = 0;
    private slideWidth = 0;
    private sliderViewWidth = 0;

    private lastPosition = 0;
    private lastSlide: number | undefined;

    private indicatorMap = new Map<number, Element>();
    private slideMap = new Map<number, Element>();

    private intervalRef: number | null = null;

    private disableTranstionClass = 'disable-transition';

    constructor(private hostElement: Element, private autoplay = false, private autoplayInterval = 3000) {
        // Query elements beneath the hostElement
        this.viewEl = hostElement.getElementsByClassName('slider02-view')[0] as HTMLElement;
        this.viewWidthEl = hostElement.getElementsByClassName('slider02-view-width')[0];
        this.panelEl = hostElement.getElementsByClassName('slider02-panel')[0] as HTMLElement;
        this.prevButton = hostElement.getElementsByClassName('slider02-action-prev')[0];
        this.nextButton = hostElement.getElementsByClassName('slider02-action-next')[0];
        // this.indicatorContainerEL = hostElement.getElementsByClassName('slider02-indicator-container')[0];

        this.slideItems = Array.from(hostElement.getElementsByClassName('slide02'));
        this.slideCount = this.slideItems.length - 1;
        this.slideItems.forEach((n, i) => this.slideMap.set(i, n));

        this.slideWidth = this.slideItems[0].clientWidth;
        this.sliderViewWidth = this.viewWidthEl.clientWidth;

        // Render Indicators if the container is present
        // if (!!this.indicatorContainerEL) {
        //     this.renderIndicators();
        // }

        this.setStateClasses(this.currentItem);

        // Start Autoplay if enables
        if (this.autoplay) {
            this.intervalRef = setInterval(() => {
                if (this.currentItem + 1 > this.slideCount) {
                    this.switchTo(0);
                } else {
                    this.switchTo(this.currentItem + 1);
                }
            }, this.autoplayInterval) as any;
        }

        // Add Event Listeners to window resize to update the slide with,
        const resizeEnd = debounce(() => {
            this.hostElement.classList.remove(this.disableTranstionClass);

            // if (!!this.indicatorContainerEL) {
            //     this.renderIndicators();
            // }
        }, 0);

        window.addEventListener('resize', () => {
            this.slideWidth = this.slideItems[0].clientWidth;
            this.sliderViewWidth = this.viewWidthEl.clientWidth;

            this.hostElement.classList.add(this.disableTranstionClass);
            this.switchTo(this.currentItem);
            resizeEnd();
        });

        // Add Event Listeners to Next and Previous Buttons if they exist
        if (!!this.prevButton) {
            this.prevButton.addEventListener('click', () => this.prev());
        }

        if (!!this.nextButton) {
            this.nextButton.addEventListener('click', () => this.next());
        }

        // Add Touch Geatures
        const panelHammer = new Hammer(this.viewEl);
        panelHammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        panelHammer.on('swipeleft', (event) => this.swipeNext(event));
        panelHammer.on('swiperight', (event) => this.swipePrev(event));
        panelHammer.on('panmove', (event) => this.panMove(event));
        panelHammer.on('panend', (event) => this.panEnd(event));
        panelHammer.on('pancancel', () => this.panCancel());
    }

    public prev() {
        this.clearAutoplay();
        const slide = Math.round(this.currentItem - this.sliderViewWidth / this.slideWidth);
        this.switchTo(slide);
    }

    public next() {
        this.clearAutoplay();
        const slide = Math.round(this.currentItem + this.sliderViewWidth / this.slideWidth);
        this.switchTo(slide);
    }

    public switchTo(item: number) {
        this.lastSlide = this.slideItems.length - this.slidesPrView;

        if (item <= 0) {
            item = 0;
        } else if (item > this.lastSlide) {
            item = this.lastSlide;
        }

        this.lastPosition = -Math.floor(item * this.slideWidth);
        this.translateX(this.lastPosition);

        this.currentItem = item;
        this.setStateClasses(this.currentItem);

        // etc [4, 5, 6, 7]
        // this.changeView.next(Array.from(Array(this.slidesPrView).keys()).map(n => n + slide));
    }

    private get slidesPrView() {
        return Math.round(this.sliderViewWidth / this.slideWidth);
    }

    private swipePrev($event: HammerInput) {
        this.clearAutoplay();

        $event.srcEvent.stopPropagation();
        const slide = Math.round(this.currentItem - 1);
        this.switchTo(slide);
    }

    private swipeNext($event: HammerInput) {
        this.clearAutoplay();

        $event.srcEvent.stopPropagation();
        const slide = Math.round(this.currentItem + 1);
        this.switchTo(slide);
    }

    private panMove($event: HammerInput) {
        this.clearAutoplay();
        if (!this.hostElement.classList.contains(this.disableTranstionClass)) {
            this.hostElement.classList.add(this.disableTranstionClass);
        }
        this.translateX(this.lastPosition + $event.deltaX);
    }

    private panEnd($event: HammerInput) {
        this.hostElement.classList.remove(this.disableTranstionClass);

        // Find out which slide to stick to;
        const slidesMoved =
            Math.abs($event.deltaX % this.slideWidth) > this.slideWidth / 2
                ? Math.floor($event.deltaX / this.slideWidth)
                : Math.ceil($event.deltaX / this.slideWidth);

        const slide = this.currentItem + slidesMoved * -1;
        this.switchTo(slide);
    }

    private panCancel() {
        this.hostElement.classList.remove(this.disableTranstionClass);
        this.switchTo(this.currentItem);
    }

    private translateX(x: number) {
        if ('requestAnimationFrame' in window) {
            window.requestAnimationFrame(() => {
                this.panelEl.style.transform = `translateX(${x}px)`;
            });
        } else {
            this.panelEl.style.transform = `translateX(${x}px)`;
        }
    }

    private setStateClasses(index: number) {
        const indicator = index / this.slidesPrView;
        // set is-active class for indicators
        this.indicatorMap.forEach((el, key) => {
            if (key === indicator && !el.classList.contains('is-active')) {
                el.classList.add('is-active');
            }
            if (key !== indicator && el.classList.contains('is-active')) {
                el.classList.remove('is-active');
            }
        });

        // set is-active class for slides
        this.slideMap.forEach((el, key) => {
            if (key >= index && key < index + this.slidesPrView && !el.classList.contains('is-active')) {
                el.classList.add('is-active');
            }
            if ((key < index || key >= index + this.slidesPrView) && el.classList.contains('is-active')) {
                el.classList.remove('is-active');
            }
        });

        // set is-disabled class for previous button
        if (!!this.prevButton) {
            if (index === 0 && !this.prevButton.classList.contains('is-disabled')) {
                this.prevButton.classList.add('is-disabled');
            }
            if (index !== 0 && this.prevButton.classList.contains('is-disabled')) {
                this.prevButton.classList.remove('is-disabled');
            }
        }
        // set is-disabled class for next button
        if (!!this.nextButton) {
            if (index + this.slidesPrView >= this.slideCount + 1 && !this.nextButton.classList.contains('is-disabled')) {
                this.nextButton.classList.add('is-disabled');
            }
            if (index + this.slidesPrView < this.slideCount + 1 && this.nextButton.classList.contains('is-disabled')) {
                this.nextButton.classList.remove('is-disabled');
            }
        }
    }

    // private renderIndicators() {
    //     const slideCount = Math.floor((this.slideCount + 1) / this.slidesPrView);

    //     // Fastest way to clear all children from an element
    //     while (this.indicatorContainerEL.firstChild) {
    //         this.indicatorContainerEL.removeChild(this.indicatorContainerEL.firstChild);
    //     }

    //     const slideCountArr = Array.from(Array(slideCount).keys());
    //     slideCountArr.forEach(i => {
    //         const el = document.createElement('SPAN');
    //         el.classList.add('slider02-indicator');
    //         // el.innerText = i.toString(); // add indicator is possible
    //         el.addEventListener('click', () => this.switchTo(i * this.slidesPrView));
    //         this.indicatorContainerEL.appendChild(el);
    //         this.indicatorMap.set(i, el);
    //     });
    // }

    private clearAutoplay() {
        if (!!this.intervalRef) {
            clearInterval(this.intervalRef as any);
            this.intervalRef = null;
        }
    }
}
