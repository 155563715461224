// https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// Modified for typescript
export function debounce<F extends (...args: any[]) => void>(func: F, waitMilliseconds = 50, isImmediate = false): F {
    let timeout: number | null;
    return function(this: any, ...args: any[]) {
        const context = this;
        const later = () => {
            timeout = null;
            if (!isImmediate) { func.apply(context, args); }
        };
        const callNow = isImmediate && !timeout;
        if (timeout !== null) { clearTimeout(timeout); }
        timeout = setTimeout(later, waitMilliseconds);
        if (callNow) { func.apply(context, args); }
    } as any;
}
