// import './opacity-layer.scss';

export class OpacityLayer {
    // private static _instance: OpacityLayer;

    public isActive = false;

    private scrolly = 0;
    private element: HTMLElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.id = 'opacity-layer';
        document.body.appendChild(this.element);
    }

    // public static get Instance(): OpacityLayer {
    //     // Store Opacity layer on window because singletons is not shared across bundles
    //     return (window as any).opacityLayer || ((window as any).opacityLayer = new OpacityLayer());
    // }

    // public static get Instance()
    // {
    //     // Do you need arguments? Make it a regular static method instead.
    //     return this._instance || (this._instance = new this());
    // }

    public enable() {
        this.isActive = true;
        this.element.style.display = 'block';
        document.body.style.overflow = 'hidden';
        // document.body.style.paddingRight = this.getScrollbarWidth() + 'px';
        window.requestAnimationFrame(() => {
            this.element.style.opacity = '0.25';
            this.scrolly = window.scrollY;
        });
    }

    public disable() {
        this.isActive = false;
        window.requestAnimationFrame(() => {
            this.element.style.opacity = '0';
            document.body.style.overflow = '';
        // document.body.style.paddingRight = '0';

            setTimeout(() => {
                this.element.style.display = 'none';
                this.scrolly = window.scrollY;
                window.scrollTo(0, this.scrolly); // values are x,y-offset
            }, 300);
        });
    }

    // private getScrollbarWidth() {
    //     const scrollDiv = document.createElement('div');

    //     scrollDiv.style.width = ' 100px';
    //     scrollDiv.style.height = ' 100px';
    //     scrollDiv.style.overflow = ' scroll';
    //     scrollDiv.style.position = ' absolute';
    //     scrollDiv.style.top = ' -9999px';

    //     document.body.appendChild(scrollDiv);
    //     const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    //     document.body.removeChild(scrollDiv);

    //     return scrollbarWidth;
    // }

}
