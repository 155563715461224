export class Cookie {
    public static set(name: string, value: string, daysToExpire: number, addTLD = false) {
        const date = new Date();
        date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
        document.cookie = name + '=' + value + '; expires=' + date.toUTCString();

        if (addTLD) {
            document.cookie =
                name + '=' + value + '; expires=' + date.toUTCString() + '; domain=.' + Cookie.getTLD(window.location.hostname);
        }
    }

    public static get(name: string) {
        const cookieName = name + '=';
        const allCookieArray = document.cookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < allCookieArray.length; i++) {
            const temp = allCookieArray[i].trim();
            if (temp.indexOf(cookieName) === 0) {
                return temp.substring(cookieName.length, temp.length);
            }
        }
        return null;
    }

    public static getTLD(hostname: string): string {
        const segments = hostname.split('.');
        if (segments.length <= 2) {
            return hostname;
        } else {
            return segments.slice(segments.length - 2, segments.length).join('.');
        }
    }
}
