import './responsive-images.scss';

/// IE requires the following for responsive images */
// https://github.com/scottjehl/picturefill
// $ npm install --save picturefill
import 'picturefill';

// https://github.com/aFarkas/lazysizes
// $ npm install --save lazysizes
// *** Make sure to only import lazysizes once, preferably in vendors ***
import 'lazysizes';

// add typings manually in in .\src\typings.d.ts
// $ npm install --save object-fit-images
import objectFitImages from 'object-fit-images';

export class ResponsiveImages {
    constructor() {
        // IE Support for object fit
        // https://github.com/bfred-it/object-fit-images/blob/master/detailed-support-tables.md#object-fit-images--picture
        if (!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)) {
            objectFitImages('.object-fit-cover', { watchMQ: true });
            objectFitImages('.object-fit-contain', { watchMQ: true });
        }
    }
}
