export class BodyLock {
    public isActive = false;
    private scrollY = 0;

    public body = document.getElementsByTagName('BODY')[0];

    public lock() {
        this.scrollY = window.scrollY;
        this.body.classList.add('has-overlay');
    }

    public unlock() {
        this.body.classList.remove('has-overlay');
        window.scrollTo(0, this.scrollY); // values are x,y-offset
    }
}
