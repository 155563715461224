import Player, { Options } from '@vimeo/player';
import './../player.scss';
/**
 * API Reference: https://developer.vimeo.com/player/sdk/reference
 * Require: npm install @vimeo/player @types/vimeo__player --save
 */

/**
 * NOTE:
 * The player SDK works with any of the following browsers: Microsoft Edge,
 * Internet Explorer 11 or better, Chrome, Firefox, Safari, and Opera.
 */
export class VimeoEmbed {
    private vimeoId: number;
    private player!: Player;

    private playButton: HTMLElement;
    private playerContainer: HTMLElement;

    constructor(private component: Element) {
        this.playerContainer = this.component.getElementsByClassName('player-container')[0] as HTMLElement;
        this.playButton = this.component.getElementsByClassName('play-button')[0] as HTMLElement;
        this.vimeoId = parseInt(this.component.getAttribute('data-video-vimeo') as string, 10);

        this.createPlayer(this.playerContainer, this.vimeoId);
        this.playButton.addEventListener('click', () => this.play());
    }

    public play() {
        this.player.play();
    }

    private createPlayer(element: HTMLElement, vimeoId: number) {

        this.player = new Player(element, {
            playsinline: true,
            id: vimeoId,
            byline: false,
            loop: false,
            responsive: true,
            title: true
        } as Options);

        this.player.on('bufferend', () => {
            this.component.classList.remove('is-buffering');
        });

        this.player.on('bufferstart', () => {
            this.component.classList.add('is-buffering');
        });

        this.player.on('ended', () => {
            this.clearStateClasses();
            this.component.classList.add('is-ended');
        });

        this.player.on('pause', () => {
            this.clearStateClasses();
            this.component.classList.add('is-paused');
        });

        this.player.on('play', () => {
            this.clearStateClasses();
            this.component.classList.add('is-playing');
        });
    }

    private clearStateClasses() {
        this.component.classList.remove('is-cued');
        this.component.classList.remove('is-buffering');
        this.component.classList.remove('is-playing');
        this.component.classList.remove('is-paused');
        this.component.classList.remove('is-ended');
    }
}
